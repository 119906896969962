import React from 'react';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { IconArtificialIntelligence } from '@wds/icons';
import { Typography } from '@wds/typography';
import { createUseStyles } from 'react-jss';
import { generateMetricsAttribute } from '@sm/metrics';
import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import { MetricsProps } from '~helpers/types';

const useStyles = createUseStyles((theme: CmsWrenchTheme) => {
  return {
    secondaryCTA: {
      color: theme.palette.text.light,
      fontSize: theme.type.fontSize.bodySm,
      textDecoration: 'underline',
    },
  };
});

const BuildWithAISection = ({
  splitHeroPrimaryCTA,
  splitHeroSecondaryCTA,
  metricsProps,
}: {
  splitHeroPrimaryCTA: string;
  splitHeroSecondaryCTA: string;
  metricsProps: MetricsProps;
}): React.ReactElement => {
  const classes = useStyles();
  const { utSource, utSource2, utSource3, amplitudeEvent, templateType, surveyId, collectorId, respondentId } =
    metricsProps;
  const SIGNUP_URL = `/sign-up/?ut_source=${utSource}&ut_source2=${utSource2}&ut_source3=${utSource3}`;
  const BUILD_WITH_AI_URL = `${SIGNUP_URL}&ep=%2Fcreate%2Fbuild-with-ai%3Fut_source%3D${utSource}%26ut_source2%3D${utSource2}%26ut_source3%3D${utSource3}`;

  return (
    <>
      <Box mb={4} display="inline-block">
        <Button color="success" size="md" href={BUILD_WITH_AI_URL}>
          <IconArtificialIntelligence title="AI Icon" />
          {splitHeroPrimaryCTA}
        </Button>
      </Box>
      <Typography variant="bodySm" color="light" component="div">
        <a
          href={SIGNUP_URL}
          className={classes.secondaryCTA}
          data-sm-metrics={generateMetricsAttribute({
            data: {
              amplitudeEvent,
              itemSelected: 'secondary CTA',
              templateType,
              surveyId,
              collectorId,
              respondentId,
              log: {
                message: `${utSource}-${utSource2}-${utSource3}`,
              },
            },
          })}
        >
          {splitHeroSecondaryCTA}
        </a>
      </Typography>
    </>
  );
};

export default BuildWithAISection;
