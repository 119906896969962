// Copied over from original component
//     apps/respweb/source/app/components/surveyThanksLogo/ColoredLogoWithText.tsx
// Only some styles have been modified. The component structure remains the same.
import React, { useContext } from 'react';
import { createUseStyles } from 'react-jss';
import { StaticContext } from '@sm/webassets/StaticContext';
import { t } from '@sm/intl';
import { generateMetricsAttribute } from '@sm/metrics';
import { LogoWithText, Logo } from '@sm/webassets/Logos';
import { createURL } from '@sm/utils';

import { Box } from '@wds/box';
import { Typography } from '@wds/typography';

import { CmsWrenchTheme } from '~app/styles/cmsTheme';
import { COPY } from '~app/components/SimpleLogoHeader';

export type Props = {
  utSource: string;
  utSource2: string;
  isThanksText?: boolean;
  amplitudeEvent?: string;
};

const useStyles = createUseStyles(({ type, breakpoints, spacing, brandColors }: CmsWrenchTheme) => {
  const mediaQueryMinScreenMD = `@media (min-width: ${breakpoints.md}px)`;
  return {
    mainBox: {
      width: '100%',
      height: '74px',
    },
    logo: {
      height: 24,
      [mediaQueryMinScreenMD]: {
        height: 44,
      },
    },
    thankslogo: {
      fontSize: type.fontSize.cardTitle,
      fontWeight: type.fontWeight.medium,
      alignSelf: 'center',
      marginTop: -spacing[1],
      paddingRight: spacing[2],
      marginBottom: 0,
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        fontSize: (type.fontSize.cardTitle as number) + 6,
      },
      '& svg': {
        '& g': {
          color: brandColors.primary.heritageGreen,
          fill: brandColors.primary.heritageGreen,
        },
      },
    },
    // Set custom styles to force the LogoWithText to the current Logo Coloring
    customClass: {
      '& div span a': {
        color: brandColors.primary.cleanSlate,
      },
      '& g': {
        color: brandColors.primary.cleanSlate,
        fill: brandColors.primary.cleanSlate,
      },
    },
  };
});

const ColoredLogoWithText = ({
  utSource,
  utSource2,
  isThanksText = false,
  amplitudeEvent,
}: Props): React.ReactElement => {
  const { mainBox, thankslogo, logo, customClass } = useStyles();
  const utSource3 = 'ColoredLogoWithText';
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  return (
    <Box display="flex" justifyContent="center" alignItems="center" className={mainBox}>
      <a
        style={{ lineHeight: '0rem' }}
        aria-label={t(COPY.LOGO_ARIA_LABEL)}
        data-testid="ColoredLogoWithText__LogoWithText"
        href={createURL('/', {
          ut_source: utSource,
          ut_source2: utSource2,
          ut_source3: utSource3,
          ut_ctatext: 'LogoWithText',
        })}
        data-sm-metrics={generateMetricsAttribute({
          data: {
            amplitudeEvent,
            itemSelected: 'logo',
            templateType,
            surveyId,
            collectorId,
            respondentId,
            log: {
              message: `${utSource}-${utSource2}-${utSource3}-LogoWithText`,
            },
          },
        })}
      >
        {isThanksText ? <Logo className={thankslogo} /> : <LogoWithText className={`${logo} ${customClass}`} />}
      </a>
      {isThanksText && (
        <Typography variant="cardTitle" weight="medium" component="span" className={logo}>
          Thank you for taking this survey.
        </Typography>
      )}
    </Box>
  );
};

export default ColoredLogoWithText;
