import { createSurveyStyles } from '@sm/webassets/SurveyTheme/context';

const whiteImportant = 'white !important';

const useSurveyStyles = createSurveyStyles(theme => ({
  imageWrapper: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  aiImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transition: 'opacity 1s ease-in-out',
  },
  transparent: {
    opacity: 0,
  },
  opaque: {
    opacity: 1,
  },
  signupButton: {
    margin: {
      top: 32,
    },
  },
  thanksContainer: {
    '& span': {
      color: whiteImportant,
    },
    '& g': {
      color: whiteImportant,
      fill: whiteImportant,
    },
  },
}));

export default useSurveyStyles;
