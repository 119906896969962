import React from 'react';
import { t } from '@sm/intl';
import { generateMetricsAttribute } from '@sm/metrics';
import { createURL } from '@sm/utils';
import { Box } from '@wds/box';
import { LogoWithText } from '@sm/webassets/Logos';
import { Typography } from '@wds/typography';
import { COPY } from '../SimpleLogoHeader';
import BuildWithAISection from './BuildWithAISection';
import SplitHeroButton from './SplitHeroButton';
import { SplitHeroCTASectionProps } from '~helpers/types';

const SplitHeroCTASection = ({
  classes,
  boldHeader,
  subHeaderContent,
  splitHeroDarkBackground,
  buildWithAI,
  primaryUrl,
  primaryCtaText,
  signUpUrl,
  splitHeroSecondaryCTA,
  metricsProps,
}: SplitHeroCTASectionProps): React.ReactElement => (
  <Box display="flex" justifyContent="center" pt={7} pb={8} px={4}>
    <Box className={classes.ctaWrapper}>
      <a
        aria-label={t(COPY.LOGO_ARIA_LABEL)}
        href={createURL('/', {
          ut_source: metricsProps.utSource,
          ut_source2: metricsProps.utSource2,
          ut_source3: metricsProps.utSource3,
          ut_ctatext: 'LogoWithText',
        })}
        data-sm-metrics={generateMetricsAttribute({
          data: {
            amplitudeEvent: metricsProps.amplitudeEvent,
            itemSelected: 'logo',
            templateType: metricsProps.templateType,
            surveyId: metricsProps.surveyId,
            collectorId: metricsProps.collectorId,
            respondentId: metricsProps.respondentId,
            log: {
              message: `${metricsProps.utSource}-${metricsProps.utSource2}-${metricsProps.utSource3}-LogoWithText`,
            },
          },
        })}
      >
        <LogoWithText color="alt" className={classes.logo} />
      </a>
      <Box className={`${classes.headerFontSize} ${classes.strangeBox}`} pt={5} pb={3}>
        <Typography component="section" variant="hero1" color={splitHeroDarkBackground ? 'light' : 'dark'}>
          <span className={classes.bold}>{boldHeader}</span>
        </Typography>
      </Box>
      <Box pb={6} className={classes.subHeaderFontSize}>
        <Typography
          component={buildWithAI ? 'p' : 'div'}
          variant={buildWithAI ? 'body' : 'cardTitle'}
          color={splitHeroDarkBackground ? 'light' : 'dark'}
        >
          {subHeaderContent}
        </Typography>
      </Box>
      {buildWithAI ? (
        <BuildWithAISection
          splitHeroPrimaryCTA={primaryCtaText}
          splitHeroSecondaryCTA={splitHeroSecondaryCTA}
          metricsProps={metricsProps}
        />
      ) : (
        <Box className={classes.buttonBox}>
          <Box pr={6} pb={5}>
            <SplitHeroButton
              href={primaryUrl}
              text={primaryCtaText}
              metricsData={{ itemSelected: 'primary CTA', ut_ctatext: primaryCtaText }}
              {...metricsProps}
            />
          </Box>
          {splitHeroSecondaryCTA?.length !== 0 && (
            <SplitHeroButton
              href={signUpUrl}
              text={splitHeroSecondaryCTA}
              variant="ghost"
              metricsData={{ itemSelected: 'secondary CTA', ut_ctatext: splitHeroSecondaryCTA }}
              {...metricsProps}
            />
          )}
        </Box>
      )}
    </Box>
  </Box>
);

export default SplitHeroCTASection;
