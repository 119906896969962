import React, { useContext } from 'react';
import { StaticContext } from '@sm/webassets/StaticContext';
import { t, defineMessages } from '@sm/intl';
import { Box } from '@wds/box';
import { Grid } from '@wds/grid';
import { StaticImageData } from 'next/image';
import HeroFormCTA from '~app/components/HeroFormCTA';
import heroImgV1 from '~static/images/dareToAsk/large-dark-text.webp';
import heroImgV2 from '~static/images/dareToAsk/large-light-text.webp';
import dareToAskUS from '~static/images/dareToAsk/dare-to-ask-us.gif';
import dareToAskUK from '~static/images/dareToAsk/dare-to-ask-uk.gif';
import bwaiGIFTest from '~static/images/bwaiTest/bwai_gif_test.webp';
import useStyles from './useStyles';
import HeroTextBanner from './HeroTextBanner';
import { UK_PATH_LINKS, US_PATH_LINKS } from '../constants';
import { SplitHeroProps } from '~helpers/types';
import SplitHeroImage from './SplitHeroImage';
import SplitHeroCTASection from './SplitHeroCTASection';

export const SPLIT_HERO_COPY = defineMessages({
  BUTTON_LABEL: {
    id: 'SplitHero.ButtonLabel',
    defaultMessage: 'Learn more',
    description: '[Type: label][Vis: high] - The text shown in the pages primary CTA',
  },
  IMAGE_LABEL: {
    id: 'SplitHero.ImageLabel',
    defaultMessage: 'SurveyMonkey Branding',
    description: '[Type: label][Vis: low] - The aria text shown in the pages primary image',
  },
});

const imageMap: { [key: string]: string | StaticImageData } = {
  dareToAskLightText: heroImgV2,
  dareToAskUS,
  dareToAskUK,
  bwaiGIFTest,
  default: heroImgV1,
};

const getImageVariant = (splitHeroImage: string): string | StaticImageData =>
  imageMap[splitHeroImage] || imageMap.default;

const getSecondaryUrl = (isDareToAskCreate: boolean, isUkSource: boolean): string => {
  if (isDareToAskCreate) {
    return '/create/start';
  }
  if (isUkSource) {
    return UK_PATH_LINKS?.ukSignupUrl;
  }
  return '/sign-up';
};

const getFinalUrls = (
  isSwapRequired: boolean,
  primaryUrl: string,
  secondaryUrl: string
): { finalPrimaryUrl: string; finalSecondaryUrl: string } => {
  if (isSwapRequired) {
    return { finalPrimaryUrl: secondaryUrl, finalSecondaryUrl: primaryUrl };
  }
  return { finalPrimaryUrl: primaryUrl, finalSecondaryUrl: secondaryUrl };
};

const SplitHero = ({
  boldHeader,
  formCopy,
  splitHeroDarkBackground = false,
  splitHeroCTA = false,
  splitHeroPrimaryCTA = '',
  splitHeroSecondaryCTA = '',
  splitHeroImage = '',
  splitHeroTextCentered = false,
  subHeader: subHeaderContent,
  utSource,
  utSource2,
  buildWithAI = false,
}: SplitHeroProps): React.ReactElement => {
  const classes = useStyles();
  const utSource3 = 'SplitHero';
  const amplitudeEvent = 'endpage user interaction';
  const isUkSource = ['DareToAskPracticalUk', 'DareToAskZestyUk'].includes(utSource2);
  const isDareToAskCreate = ['DareToAskCreate'].includes(utSource2);
  const isDareToAskV3SignUp = ['DareToAskV3SignUp'].includes(utSource2);
  const {
    smParams: { surveyId, collectorId, respondentId },
    templateType,
  } = useContext(StaticContext);

  const imageVariant = getImageVariant(splitHeroImage);

  const metricsProps = {
    amplitudeEvent,
    templateType,
    surveyId,
    collectorId,
    respondentId,
    utSource,
    utSource2,
    utSource3,
  };
  const primaryCtaText = splitHeroPrimaryCTA.length ? splitHeroPrimaryCTA : t(SPLIT_HERO_COPY.BUTTON_LABEL);
  const primaryUSUrl = splitHeroPrimaryCTA.length ? US_PATH_LINKS.exploreUsCtaUrl : '/mp/dare-to-ask/';
  const primaryUrl = isUkSource ? UK_PATH_LINKS?.exploreUkCtaUrl : primaryUSUrl;

  const secondaryUrl = getSecondaryUrl(isDareToAskCreate, isUkSource);
  const { finalPrimaryUrl, finalSecondaryUrl } = getFinalUrls(
    isDareToAskCreate || isDareToAskV3SignUp,
    primaryUrl,
    secondaryUrl
  );

  return (
    <>
      {splitHeroTextCentered && (
        <HeroTextBanner
          boldHeader={boldHeader}
          splitHeroDarkBackground={splitHeroDarkBackground}
          subHeader={subHeaderContent}
          utSource={utSource}
          utSource2={utSource2}
        />
      )}
      <Box
        className={splitHeroDarkBackground && buildWithAI ? classes.darkBackgroundAIBackground : classes.darkBackground}
        pt={splitHeroDarkBackground ? 10 : undefined}
      >
        <Grid noBleeds noGutters>
          <Grid.Item xl={6} lg={6} md={splitHeroCTA ? 12 : 6} sm={12} xs={12}>
            {splitHeroCTA ? (
              <SplitHeroCTASection
                classes={classes}
                boldHeader={boldHeader}
                subHeaderContent={subHeaderContent}
                splitHeroDarkBackground={splitHeroDarkBackground}
                buildWithAI={buildWithAI}
                primaryUrl={finalPrimaryUrl}
                primaryCtaText={primaryCtaText}
                signUpUrl={finalSecondaryUrl}
                splitHeroSecondaryCTA={splitHeroSecondaryCTA}
                metricsProps={metricsProps}
              />
            ) : (
              <Box p={3}>
                <HeroFormCTA className={classes.ctaForm} utSource={utSource} utSource2={utSource2} {...formCopy} />
              </Box>
            )}
          </Grid.Item>
          <Grid.Item xl={6} lg={6} md={splitHeroCTA ? 12 : 6} sm={12} xs={12}>
            <SplitHeroImage
              imageVariant={imageVariant}
              imagePlacement={classes.imagePlacement}
              imageAlt={t(SPLIT_HERO_COPY.IMAGE_LABEL)}
              {...metricsProps}
            />
          </Grid.Item>
        </Grid>
      </Box>
    </>
  );
};

export default SplitHero;
