import React from 'react';
import { defineMessages, t } from '@sm/intl';
import { createURL } from '@sm/utils';

import EndpageHeader from '~app/components/EndpageHeader';
import RebrandEpFormCore from '~app/components/RebrandEpFormCore';
import { ProductsCalloutListItem } from '~app/components/ProductsCalloutList/ProductsCalloutList';

import PerksIconWhite from '~static/images/icons/perks-icon-white.svg';
import EnterpriseIconWhite from '~static/images/icons/enterprise-icon-white.svg';
import { getSrc } from '~app/helpers/assetLoading';

export const COPY = defineMessages({
  ENDPAGE_HEADER_COPY: {
    id: 'SurveyThanks.RebrandEpForm.EndpageHeaderCopy',
    defaultMessage: 'Thank you for taking this survey.',
    description: '[Type: Header][Vis: med] - Informs the viewer that this survey has just completed',
  },

  PRODUCT_CALLOUT_PERKS_HEADING: {
    id: 'SurveyThanks.RebrandEpForm.ProductCalloutPerksHeader',
    defaultMessage: 'Is your company missing key perks?',
    description:
      '[Type: Header][Vis: med] - Tagline to help entice users to views click the cta below. The CTA leads to another signup page with minimal information.',
  },
  PRODUCT_CALLOUT_PERKS_BODY: {
    id: 'SurveyThanks.RebrandEpForm.ProductCalloutPerkseBody',
    defaultMessage:
      'People love their work perks. Send a survey to find out if you’re offering the incentives employees and potential candidates want.',
    description: '[Type: Paragraph][Vis: med] - Gives context to the above Tagline',
  },
  PRODUCT_CALLOUT_PERKS_CTA: {
    id: 'SurveyThanks.RebrandEpForm.ProductCalloutPerksCTA',
    defaultMessage: 'Find out',
    description: '[Type: Button][Vis: med] - The text for a button that leads users to another sign up page',
  },

  PRODUCT_CALLOUT_ENTERPRISE_HEADING: {
    id: 'SurveyThanks.RebrandEpForm.ProductCalloutEnterpriseHeader',
    defaultMessage: 'Take your business to the next level',
    description: '[Type: Header][Vis: med] - Tagline to help user learn more about SurveyMonkey Enterprise suite',
  },
  PRODUCT_CALLOUT_ENTERPRISE_BODY: {
    id: 'SurveyThanks.RebrandEpForm.ProductCalloutEnterpriseBody',
    defaultMessage:
      'Get access to powerful solutions that help you quickly collect feedback from your customers, prospects, and employees.',
    description: '[Type: Paragraph][Vis: med] - Expands on the above header line.',
  },
  PRODUCT_CALLOUT_ENTERPRISE_CTA: {
    id: 'SurveyThanks.RebrandEpForm.ProductCalloutEnterpriseCTA',
    defaultMessage: 'Learn more',
    description:
      "[Type: Button][Vis: med] - The button text that leads users to a marketing page to learn more about SurveyMonkey's enterprise plans",
  },
});

function RebrandEpForm({
  experiment,
  utSource2 = 'RebrandEpForm',
}: {
  experiment?: boolean;
  utSource2?: string;
}): React.ReactElement {
  const utSource = 'survey-thanks';
  const utSource3 = 'ProductCalloutItem';

  const CALLOUT_ITEM_SIGN_UP: ProductsCalloutListItem = {
    icon: getSrc(PerksIconWhite),
    heading: t(COPY.PRODUCT_CALLOUT_PERKS_HEADING),
    copy: t(COPY.PRODUCT_CALLOUT_PERKS_BODY),
    href: createURL('/user/sign-up/', {
      ut_source: utSource,
      ut_source2: utSource2,
      ut_source3: utSource3,
      ut_ctatext: COPY.PRODUCT_CALLOUT_PERKS_CTA.defaultMessage,
    }),
    ctaText: COPY.PRODUCT_CALLOUT_PERKS_CTA,
    ctaUsage: 'sign-up secondary',
    ctaColor: 'sabaeus',
    name: 'perks',
  };

  const CALLOUT_ITEM_ENTERPRISE: ProductsCalloutListItem = {
    icon: getSrc(EnterpriseIconWhite),
    heading: t(COPY.PRODUCT_CALLOUT_ENTERPRISE_HEADING),
    copy: t(COPY.PRODUCT_CALLOUT_ENTERPRISE_BODY),
    href: createURL('/mp/enterprise/', {
      ut_source: utSource,
      ut_source2: utSource2,
      ut_source3: utSource3,
      ut_ctatext: COPY.PRODUCT_CALLOUT_ENTERPRISE_CTA.defaultMessage,
    }),
    ctaText: COPY.PRODUCT_CALLOUT_ENTERPRISE_CTA,
    ctaUsage: 'enterprise cta',
    ctaColor: 'midnight',
    name: 'enterprise',
  };

  const headerType = experiment ? 'static' : 'thanks';
  const headerColor = experiment ? 'vine' : 'sabaeus';

  return (
    <>
      <EndpageHeader
        copy={t(COPY.ENDPAGE_HEADER_COPY)}
        hasLogo={headerType === 'thanks' || headerColor === 'vine'}
        headerColor={headerColor}
        headerType={headerType}
      />
      <RebrandEpFormCore
        utSource={utSource}
        utSource2={utSource2}
        calloutItem1={CALLOUT_ITEM_SIGN_UP}
        calloutItem2={CALLOUT_ITEM_ENTERPRISE}
      />
    </>
  );
}

export default RebrandEpForm;
