import React, { useState, useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames';

import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { Input } from '@wds/input';
import { InputGroup, InputGroupAddon } from '@wds/input-group';
import { Button } from '@wds/button';
import { IconArtificialIntelligence } from '@wds/icons';

import ColoredLogoWithText from './ColoredLogoWithText';

import { getSrc } from '~app/helpers/assetLoading';
import ExitInterviewImage from '~static/images/expGrow4165/exit-interview-survey.webp';
import UniversitySurveyImage from '~static/images/expGrow4165/university-survey.webp';
import WebinarSurveyImage from '~static/images/expGrow4165/webinar-survey.webp';

import useSurveyStyles from './useStyles';

// COPY is in draft mode as long this is an expermient and we don't translate.
// That is why we don't use defineMessages here.
const COPY = {
  AI_BUTTON_LABEL: {
    id: 'SurveyThanks.BuildWithAI.AIButtonLabel',
    defaultMessage: 'Go to Build with AI',
  },
  HEADER_T1: {
    id: 'SurveyThanks.BuildWithAI.HeaderT1',
    defaultMessage: 'Create your own survey with AI and get insights fast',
  },
  HEADER_T2: {
    id: 'SurveyThanks.BuildWithAI.HeaderT1',
    defaultMessage: 'Create your own survey in as little as 30 seconds',
  },
  SECONDARY_HEADER: {
    id: 'SurveyThanks.BuildWithAI.SecondaryHeader',
    defaultMessage: 'Create surveys from scratch, templates, and more',
  },
  PROMPT_TEXT_1: {
    id: 'SurveyThanks.BuildWithAI.PromptText1',
    defaultMessage: 'We need a survey for employee exit interviews. It should assess how the employee...',
  },
  PROMPT_TEXT_2: {
    id: 'SurveyThanks.BuildWithAI.PromptText2',
    defaultMessage: 'My company is hosting a webinar. We want to ask attendees for feedback after the...',
  },
  PROMPT_TEXT_3: {
    id: 'SurveyThanks.BuildWithAI.PromptText3',
    defaultMessage: 'Our university is conducting a survey on student satisfaction. We’re interested in...',
  },
  SIGNUP_BUTTON_CTA: {
    id: 'SurveyThanks.BuildWithAI.SignupButtonCTA',
    defaultMessage: 'Sign up',
  },
};

const TITLE_COPY: { [key in 'treatment1' | 'treatment2']: string } = {
  treatment1: COPY.HEADER_T1.defaultMessage,
  treatment2: COPY.HEADER_T2.defaultMessage,
};

const PLACEHOLDER_COPY = [
  COPY.PROMPT_TEXT_1.defaultMessage,
  COPY.PROMPT_TEXT_2.defaultMessage,
  COPY.PROMPT_TEXT_3.defaultMessage,
];

export const WrappedLogo: React.FC<{ utSource2: string }> = ({ utSource2 }): React.ReactElement => {
  const { thanksContainer } = useSurveyStyles();
  return useMemo(
    () => (
      <div className={thanksContainer}>
        <ColoredLogoWithText utSource="survey-thanks" utSource2={utSource2} isThanksText />
      </div>
    ),
    [thanksContainer, utSource2]
  );
};

const images = [ExitInterviewImage, WebinarSurveyImage, UniversitySurveyImage];

const keys = ['exitInterview', 'webinarSurvey', 'universitySurvey'];

type FadeImagesProps = {
  currentIndex: number;
};

const FadeImages: React.FC<FadeImagesProps> = ({ currentIndex }) => {
  const { imageWrapper, aiImage, transparent, opaque } = useSurveyStyles();

  const imageClasses = (curIndex: number, activeIndex: number): string =>
    classNames(aiImage, {
      [transparent]: curIndex !== activeIndex,
      [opaque]: curIndex === activeIndex,
    });

  return (
    <div className={imageWrapper}>
      {images.map((image, index) => (
        <img
          key={keys[index]}
          src={getSrc(image)}
          alt={`Slide ${index}`}
          className={imageClasses(index, currentIndex)}
        />
      ))}
    </div>
  );
};

function BuildWithAI({ treatment }: { treatment: 'treatment1' | 'treatment2' }): React.ReactElement {
  const { signupButton } = useSurveyStyles();

  const [currentInput, setCurrentInput] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isUserTyping, setIsUserTyping] = useState(false);
  const inputRef = useRef<HTMLInputElement>({ value: 'blah' } as HTMLInputElement);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const typingSpeed = 80;

  const titleCopy = TITLE_COPY[treatment];

  const utSource2 = treatment === 'treatment1' ? 'build-with-ai-t1' : 'build-with-ai-t2';
  // URLS (both url need utsoure3 value replaced)
  const SIGNUP_URL = `/sign-up/?ut_source=thankyou&ut_source2=${utSource2}&ut_source3=UTSOURCE3_VALUE`;
  const SIGNUP_URL_TREATMENT = `${SIGNUP_URL}&ep=%2Fcreate%2Fbuild-with-ai%3Fut_source%3Dthankyou%26ut_source2%3D${utSource2}%26ut_source3%3DUTSOURCE3_VALUE`;

  const getUrlWithUtsource3 = (url: string, value: string): string => url.replaceAll('UTSOURCE3_VALUE', value);

  // This effect handles the main animation flow of the component
  useEffect(() => {
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    if (isUserTyping) {
      return;
    }

    const handleTyping = (newText: string): void => {
      if (inputRef.current !== document.activeElement) {
        setCurrentInput(newText);
      } else {
        inputRef.current.select();
      }
    };

    if (isDeleting) {
      if (currentInput.length > 0) {
        setTimeout(() => handleTyping(currentInput.slice(0, -1)), typingSpeed * 0.5);
      } else {
        setIsDeleting(false);
        setCurrentIndex(prevIndex => (prevIndex + 1) % PLACEHOLDER_COPY.length);
      }
    } else {
      const nextValue = PLACEHOLDER_COPY[currentIndex];
      if (currentInput.length < nextValue.length) {
        setTimeout(() => handleTyping(nextValue.slice(0, currentInput.length + 1)), typingSpeed);
      } else {
        typingTimeoutRef.current = setTimeout(() => {
          setIsDeleting(true);
        }, 2000);
      }
    }
  }, [currentInput, isDeleting, currentIndex, isUserTyping, inputRef]);

  const handleFocus = (): void => {
    if (isUserTyping) {
      return;
    }
    setIsUserTyping(true);
    if (currentInput === '') {
      setCurrentInput('');
    } else {
      setCurrentInput(PLACEHOLDER_COPY[currentIndex]);
    }
    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }
  };

  return (
    <Box style={{ backgroundColor: '#224F3C' }}>
      <Box style={{ width: '960px', margin: '0 auto' }}>
        <Box display="grid" gridTemplateRows="auto auto 56px 678px" gap={5}>
          <WrappedLogo utSource2={utSource2} />

          <Typography component="h1" weight="medium" variant="pageTitle" align="center" color="light">
            {titleCopy}
          </Typography>

          <InputGroup>
            <Input
              id="storybook-input"
              ref={inputRef}
              value={currentInput}
              onFocus={handleFocus}
              onChange={e => setCurrentInput(e.target.value)}
            />
            <InputGroupAddon>
              <Box mr={2}>
                <Button color="success" size="md" href={getUrlWithUtsource3(SIGNUP_URL_TREATMENT, 'ai-cta')}>
                  <IconArtificialIntelligence title="AI Icon" />
                  {COPY.AI_BUTTON_LABEL.defaultMessage}
                </Button>
              </Box>
            </InputGroupAddon>
          </InputGroup>

          <Box mt={2} mb={2}>
            <FadeImages currentIndex={currentIndex} />
          </Box>

          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" mb={8}>
            <Typography component="h2" weight="medium" variant="sectionTitleSm" align="center" color="light">
              {COPY.SECONDARY_HEADER.defaultMessage}
            </Typography>
            <Box className={signupButton}>
              <Button href={getUrlWithUtsource3(SIGNUP_URL, 'signup-cta')} variant="ghost" color="alt" onDark>
                {COPY.SIGNUP_BUTTON_CTA.defaultMessage}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default BuildWithAI;
