import React from 'react';
import { createURL } from '@sm/utils';
import { generateMetricsAttribute } from '@sm/metrics';
import { Box } from '@wds/box';
import { UK_PATH_LINKS } from '../constants';
import { getSrc } from '~app/helpers/assetLoading';
import dareToAskUS from '~static/images/dareToAsk/dare-to-ask-us.gif';
import dareToAskUK from '~static/images/dareToAsk/dare-to-ask-uk.gif';
import { SplitHeroImageProps } from '~helpers/types';

const SplitHeroImage = ({
  imageVariant,
  imagePlacement,
  imageAlt,
  ...metricsProps
}: SplitHeroImageProps): React.ReactElement => {
  const { utSource, utSource2, utSource3, amplitudeEvent, surveyId, collectorId, respondentId } = metricsProps;
  return (
    <Box display="grid" gridTemplateRows="477px" mb={8}>
      <Box display="grid" placeItems="center">
        {imageVariant === dareToAskUS || imageVariant === dareToAskUK ? (
          <a
            href={createURL(
              imageVariant === dareToAskUK ? UK_PATH_LINKS?.exploreUkCtaUrl : '/mp/workplace-trends-for-a-new-era/',
              {
                ut_source: utSource,
                ut_source2: utSource2,
                ut_source3: utSource3,
                ut_ctatext: 'animated gif',
              }
            )}
            data-sm-metrics={generateMetricsAttribute({
              data: {
                amplitudeEvent,
                itemSelected: 'animated gif',
                surveyId,
                collectorId,
                respondentId,
                log: { message: `${utSource}-${utSource2}-${utSource3}` },
              },
            })}
          >
            <img src={getSrc(imageVariant)} alt={imageAlt} className={imagePlacement} />
          </a>
        ) : (
          <img src={getSrc(imageVariant)} alt={imageAlt} className={imagePlacement} />
        )}
      </Box>
    </Box>
  );
};

export default SplitHeroImage;
