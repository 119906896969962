const WEBLINK_ID = 'weblink-email';
export default WEBLINK_ID;

// constants/utSourcePaths.ts
export const UT_SOURCE_PATHS = {
  OPTIONAL_PATH_MWEB1: 'optionalPathsMWeb1',
  OPTIONAL_PATH_MWEB2: 'optionalPathsMWeb2',
  OPTIONAL_PATH_MAPP1: 'optionalPathsMApp1',
  OPTIONAL_PATH_MAPP2: 'optionalPathsMApp2',
};

// Platform-specific URLs
export const APP_LINKS = {
  android: 'https://play.google.com/store/apps/details?id=com.surveymonkey.rewards&pcampaignid=web_share',
  ios: 'https://apps.apple.com/us/app/surveymonkey-rewards/id1376880637',
};

export const UK_PATH_LINKS = {
  exploreUkCtaUrl: 'https://uk.surveymonkey.com/mp/workplace-trends-for-a-new-era/',
  ukSignupUrl: 'https://uk.surveymonkey.com/sign-up/',
};

export const US_PATH_LINKS = {
  exploreUsCtaUrl: 'https://surveymonkey.com/mp/workplace-trends-for-a-new-era/',
};

export const USER_RESEARCH_STANDARD_CTA = 'https://www.surveymonkey.com/r/Q7D9YKY';
export const USER_RESEARCH_STYLED_CTA = 'https://www.surveymonkey.com/r/C6KN2RH';
export const USER_RESEARCH_MOBILE_STYLE_CTA = 'https://www.surveymonkey.com/r/B8S98DY';

export const CTA_LINKS: Record<string, string> = {
  userResearchMobileStyle: USER_RESEARCH_MOBILE_STYLE_CTA,
  userResearchStandard: USER_RESEARCH_STANDARD_CTA,
  userResearchStyled: USER_RESEARCH_STYLED_CTA,
};
